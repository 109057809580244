<template>
  <div>
    <div class="page-box login">
      登录中...
    </div>
  </div>
</template>
<script>
import { authenticate, catchExpired } from '../../utils/auth'
import { getStorageItem, updateStorageItem } from '../../utils/cache'
export default {
  data () {
    return {
      code: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.code) {
      next(vm => {
        vm.code = to.query.code
        if (window.opener && !to.query.window) {
          // 弹框中，微信扫码后重定向到登录
          let url = vm.backurl + '/login'
          if (vm.$nodeEnv === 'development') {
            url = 'http://localhost:8000/login'
          }
          window.opener.location.href = url + '?window=parent&code=' + vm.code
          window.close()
        } else if (to.query.window === 'parent') {
          // 父窗口的登录页面
          console.log('code', vm.code)
          vm.login(vm.code)
        }
      })
    } else {
      next()
    }
  },
  methods: {
    login (code) {
      this.$http.post(`${this.httpRoot}/auth/login`, {code}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (res.token) {
            this.$store.dispatch('login', res.token)
          }
          if (res.auth && res.auth.nickname) {
            updateStorageItem('auth', res.auth)
            this.$store.dispatch('setUser', {name: res.auth.nickname, headurl: res.auth.headimgurl})
          }
          if (res.token) {
            // 跳转到之前页面
            let route = getStorageItem('route')
            if (route) {
              this.$router.push(route)
            } else {
              this.$router.push({name: 'home'})
            }
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
<style lang="less">
.login {
  padding-top: 40px;
  text-align: center;
}
</style>
